<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col cols="12">
            <template v-if="!isAuthenticatedReady">
                <SectionLayout>
                    <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">
                        <v-row style="height: 100%" align="center" justify="center">
                            <div class="app-splash-loader"></div>
                        </v-row>
                    </v-col>
                </SectionLayout>
            </template>
            <template v-if="isAuthenticatedReady && isAuthenticated">
                <SectionLayout>
                    <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">
                        <v-row style="height: 100%" align="center" justify="center">
                            <div class="app-splash-loader"></div>
                            <p class="mt-6">
                            <router-link :to="{ name: 'user-dashboard' }">Continue to dashboard</router-link>
                            </p>
                        </v-row>
                    </v-col>
                </SectionLayout>
            </template>
            <template v-if="isAuthenticatedReady && !isAuthenticated">
                <SectionLayout>
                    <v-col cols="12">
                        <v-row justify="center" class="pt-5 px-2 text-center">
                            <!-- <v-col style="text-align: center"> -->
                                <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1', 'font-weight-light']">Easy, secure, reliable VPN</h1>
                            <!-- </v-col> -->
                        </v-row>
                    </v-col>
                </SectionLayout>
                <SectionLayout>
                    <v-col cols="12" md="6">
                        <v-row align="center" class="pb-5" style="height: 100%">
                        <v-row justify="center" class="py-5 text-center">
                            <!-- <v-col cols="12" sm="12" md="10" lg="8" xl="6" class="pa-0" style="text-align: center"> -->
                            <!-- the 4-point statement -->
                            <!-- <h1 class="display-1 font-weight-light my-2">Protect online accounts from hackers using a sophisticated one-tap login system,<br/>and do business with confidence.</h1> -->
                                <p class="my-2 px-2"><span :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light']" style="line-height: 1.5">Browse the web with complete privacy! Hide your IP address and location from websites, and hide your browsing history from your ISP.</span></p>
                            <!-- </v-col> -->
                        </v-row>
                        <v-row justify="center" class="py-5 px-2 text-center">
                            <v-col cols="12">
                                <GetVpnButton class="ma-2"/>
                            </v-col>
                        </v-row>
                        </v-row>
                    </v-col>

                    <v-col cols="12" md="6" :order="imageOrder">
                        <v-row justify="center" class="py-5" style="height: 100%;" align="center">
                            <v-img alt="A man in a black business suit with a red cape and red mask, crossing his arms." src="@/assets/image/man-with-cape-and-mask-id1150501985_1024x1024_transparent.png" width="480" height="480" contain v-show="$vuetify.breakpoint.mdAndUp"></v-img>
                            <v-img alt="A man in a black business suit with a red cape and red mask, crossing his arms." src="@/assets/image/man-with-cape-and-mask-id1150501985_1024x1024_transparent.png" width="360" height="360" contain v-show="$vuetify.breakpoint.smAndDown"></v-img>
                        </v-row>
                    </v-col>
                <!-- <v-row>
                    <v-col cols="12">
                        <v-row justify="center">
                            <! -- <v-img src="~/assets/backgrounds/" width="500"></v-img> -- >
                            <div id="nice-divider">
                            <div id="curve1" class="color all"></div>
                            </div>
                        </v-row>
                    </v-col>
                </v-row> -->
                </SectionLayout>
                <SectionLayout backgroundColor="#000055">
                    <v-col cols="12">
                        <v-row justify="center" class="py-2">
                            <!-- <v-col style="text-align: center"> -->
                                <h1 class="font-weight-light blue--text text--lighten-4" :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']">Did you know?</h1>
                            <!-- </v-col> -->
                        </v-row>
                        <v-row justify="center" class="py-2">
                            <!-- <v-col cols="12" sm="12" md="10" lg="8" xl="6" class="pa-0"  style="text-align: center"> -->
                            <!-- the 4-point statement -->
                            <!-- <h1 class="display-1 font-weight-light my-2">Protect online accounts from hackers using a sophisticated one-tap login system,<br/>and do business with confidence.</h1> -->
                                <p class="my-2 white--text text-center"><span class="font-weight-light" :class="[$vuetify.breakpoint.mdAndUp ? 'headline' : 'title']" style="line-height: 1.5">Your ISP monitors your DNS, HTTP, and even HTTPS traffic to track what you're doing online. You need a VPN to browse with privacy.</span></p>
                            <!-- </v-col> -->
                        </v-row>
                        <v-row justify="center"  class="py-2">
                            <!-- <v-col class="text-center"> -->
                                <GetVpnButton class="ma-2"/>
                            <!-- </v-col> -->
                        </v-row>

                    </v-col>
                <!-- <v-row>
                    <v-col cols="12">
                        <v-row justify="center">
                            <! -- <v-img src="~/assets/backgrounds/" width="500"></v-img> -- >
                            <div id="nice-divider">
                            <div id="curve1" class="color all"></div>
                            </div>
                        </v-row>
                    </v-col>
                -->
                </SectionLayout>

                <SectionLayout>
        <!-- <v-col cols="12">
        <v-row class="ma-0 pa-0" justify="center">
        <v-row class="px-5" style="max-width: 1200px; padding-top: 20px; padding-bottom: 20px;"> -->
            <v-col cols="12" md="6">
                <v-row justify="center"  class="py-5">
                    <!-- <v-col style="text-align: center"> -->
                        <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light']">What is Cryptium VPN?</h1>
                    <!-- </v-col> -->
                </v-row>
                <v-row justify="center" class="py-5">
                    <!-- <v-col cols="12" sm="12" md="10" lg="8" xl="6" class="pa-0"  style="text-align: center"> -->
                    <!-- the 4-point statement -->
                    <!-- <h1 class="display-1 font-weight-light my-2">Protect online accounts from hackers using a sophisticated one-tap login system,<br/>and do business with confidence.</h1> -->
                        <p class="my-2"><span class="font-weight-light" :class="[$vuetify.breakpoint.mdAndUp ? 'headline' : 'title']" style="line-height: 1.5">We provide a secure server that you can use to hide your Internet traffic from your ISP and to hide your IP address from websites that you visit. Your ISP will only see your connection to our server, and websites will see the IP address of our server.<br/><br/>We use strong cryptography to protect your Internet traffic from prying eyes, so even connections to insecure websites will be private.</span></p>
                    <!-- </v-col> -->
                </v-row>
                <v-row justify="center"  class="py-5 px-2 text-center">
                    <v-col cols="12">
                        <GetVpnButton class="ma-2"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" md="6">
                <v-row justify="center" class="py-5" style="height: 100%;" align="center">
                    <v-img alt="A woman in a black business suit with a red cape and red mask, crossing her arms." src="@/assets/image/woman-with-cape-and-mask-id835620076_1024x1024_transparent.png" width="480" height="480" :contain="true" v-show="$vuetify.breakpoint.mdAndUp"></v-img>
                    <v-img alt="A woman in a black business suit with a red cape and red mask, crossing her arms." src="@/assets/image/woman-with-cape-and-mask-id835620076_1024x1024_transparent.png" width="360" height="360" :contain="true" v-show="$vuetify.breakpoint.smAndDown"></v-img>
                </v-row>
            </v-col>

            <!-- placeholder for explainer video -->
            <!--
            <v-col cols="12" md="6">
                <v-row justify="center" class="py-5" style="height: 100%;" align="center">
                    <div class="pb-5">
                    < ! -- NOTE: v-dialog has a width property but not height, so don't set height because it will pass through to the generated div which is invalid html -- >
                    <v-dialog v-model="explainerVideoDialog" :width="videoWidth">
                        <template v-slot:activator="{ on }">
                            < ! -- video cover image, centered -- >
                            < ! -- NOTE: if we add the "contain" property, then we must remove the border because when the image is automatically resized there is padding between the resized image and the border and it doesn't look good -- >
                            < ! -- NOTE: v-img width and height are static, do NOT use a variable here -- >
                            <v-img src="~/assets/VideoCoverWithPlayButtonCryptiumLoginShield_400x225.png" width="360" height="203" fit="inside" v-on="on" style="margin: 0 auto; border: 1px solid #aaaaaa;" alt="" title="Watch this video!"/>
                        </template>
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <iframe :width="videoWidth" :height="videoHeight" src="https://www.youtube-nocookie.com/embed/videoseries?list=PLt0tLWTKZbAC-_h4B2cJwLrehEh3jUsEJ&rel=0" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </v-dialog>
                    </div>
                </v-row>
            </v-col>
            -->
        <!-- </v-row> -->

        <!-- <v-row>
            <v-col cols="12">
                <v-row justify="center">
                    <! -- <v-img src="~/assets/backgrounds/" width="500"></v-img> -- >
                    <div id="nice-divider">
                    <div id="curve1" class="color all"></div>
                    </div>
                </v-row>
            </v-col>
        </v-row> -->
        <!-- </v-row>
        </v-col> -->
    </SectionLayout>

    <!-- <Highlights/> -->
    <!-- <FeatureOneTapLogin/> -->
    <!-- <FeatureDigitalSignature/> -->
    <!-- <FeaturePhishingProtection/> -->
    <!-- <MoreContent/> -->
    <!-- <Applications/> -->
    <!-- <Certifications/> -->

    <SectionLayout>
        <v-col cols="12">
        <v-row justify="center"  class="py-2">
            <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light']">Start browsing with privacy in just minutes!</h1>
        </v-row>
        <v-row justify="center" class="py-2">
        <!-- the 4-point statement -->
        <!-- <h1 class="display-1 font-weight-light my-2">Protect online accounts from hackers using a sophisticated one-tap login system,<br/>and do business with confidence.</h1> -->
            <p class="my-2"><span :class="[$vuetify.breakpoint.mdAndUp ? 'headline' : 'title', 'font-weight-light']" style="line-height: 1.5">Protect your browsing history from prying eyes with our easy, secure, and reliable VPN.</span></p>
        </v-row>
        <v-row justify="center"  class="py-2">
            <GetVpnButton class="ma-2"/>
        </v-row>
        </v-col>
    </SectionLayout>
    <!-- <SecurityAssessment/> -->

    <!-- DOWNLOADS -->
    <SectionLayout backgroundColor="#dddddd">
        <!-- <v-col cols="12" class="ma-0 pa-0">
        <v-row class="ma-0 pa-0" justify="center" style="background-color: #dddddd;">
        <v-row class="px-5" style="max-width: 1200px; padding-top: 20px; padding-bottom: 20px;"> -->
                <v-col cols="12">
                <div style="height: 20px;"></div>
                <v-row justify="center" class="py-2">
                    <v-col cols="0" md="1"></v-col>
                    <v-col cols="12" md="5" class="pa-0"  style="text-align: center">
                    <!-- the 4-point statement -->
                    <!-- <h1 class="display-1 font-weight-light my-2">Protect online accounts from hackers using a sophisticated one-tap login system,<br/>and do business with confidence.</h1> -->
                    <v-row justify="center">
                        <p class="my-2 grey--text text--darken-2"><span :class="[$vuetify.breakpoint.mdAndUp ? 'headline' : 'title', 'font-weight-light']" style="line-height: 1.5">Supported Devices</span></p>
                    </v-row>
                        <v-row justify="center" class="compatibility-links">
                            <!-- instead of android, ios, windows, mac, linux; we just show desktop, laptop, mobile -->
                            <router-link to="/software/?type=desktop" title="Desktop"><font-awesome-icon :icon="['fas','desktop']"/></router-link>
                            <router-link to="/software/?type=laptop" title="Laptop"><font-awesome-icon :icon="['fas','laptop']"/></router-link>
                            <router-link to="/software/?type=mobile" title="Mobile Phone or Tablet"><font-awesome-icon :icon="['fas','mobile-alt']"/></router-link>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="5" class="pa-0"  style="text-align: center">
                    <!-- the 4-point statement -->
                    <!-- <h1 class="display-1 font-weight-light my-2">Protect online accounts from hackers using a sophisticated one-tap login system,<br/>and do business with confidence.</h1> -->
                    <v-row justify="center">
                        <p class="my-2 grey--text text--darken-2"><span :class="[$vuetify.breakpoint.mdAndUp ? 'headline' : 'title', 'font-weight-light']" style="line-height: 1.5">Supported Platforms</span></p>
                    </v-row>
                    <v-row justify="center" class="compatibility-links">
                        <!-- instead of chrome, firefox, edge, opera, ...  we just list the platforms -->
                            <router-link to="/software/?platform=windows" title="Microsoft Windows"><font-awesome-icon :icon="['fab','windows']"/></router-link>
                            <router-link to="/software/?platform=apple" title="Apple Mac and iOS"><font-awesome-icon :icon="['fab','apple']"/></router-link>
                            <router-link to="/software/?platform=linux" title="GNU/Linux Distributions"><font-awesome-icon :icon="['fab','linux']"/></router-link>
                    </v-row>
                    </v-col>
                    <v-col cols="0" md="1"></v-col>
                </v-row>

                </v-col>
        <!-- </v-row>
        </v-row>
        </v-col> -->
    </SectionLayout>
            </template>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import LoginCard from '@/components/LoginCard.vue';
import GetVpnButton from '@/components/GetVpnButton.vue';
import SectionLayout from '@/layouts/SectionLayout.vue';

export default {
    components: {
        // LoginCard,
        GetVpnButton,
        SectionLayout,
    },
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        imageOrder() {
            return this.$vuetify.breakpoint.mdAndUp ? 'first' : 'last'; /* on medium screen this is on left of horizontal layout, but on small screen the image goes below the text */
        },
        videoWidth() {
            return this.$vuetify.breakpoint.mdAndUp ? '800' : '360';
        },
        videoHeight() {
            return this.$vuetify.breakpoint.mdAndUp ? '450' : '203';
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        isAuthenticated(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.redirectAuthenticatedUser();
            }
        },
    },
    methods: {
        init() {
            if (this.isAuthenticated) {
                this.redirectAuthenticatedUser();
            }
        },
        redirectAuthenticatedUser() {
            // TODO: we need to check query parameters, if there's an account id redirect to the dashboard for that account, otherwise if there's only one account, go to that one, or if there's more than one show the account selection , and if the user doens't have any accounts then show a message that they need to contact the administrator to be added to an account
            this.$router.replace({ name: 'user-dashboard' });
        },
    },
    mounted() {
        if (this.isAuthenticatedReady) {
            this.init();
        }
    },
};
</script>
