<template>
    <v-row class="ma-0 pa-0" :class="{ 'responsive-container': responsiveContainer }" :style="outerstyle">
        <v-col cols="12">
            <div :class="responsiveDiagonalTopClass" v-if="responsiveDiagonalTopClass"></div>
            <v-row :style="innerStyle" :justify="justify">
                <slot></slot>
            </v-row>
        </v-col>
    </v-row>
</template>
<style lang="css" scoped>
.responsive-container {
    position: relative;
}
/* first color is transparent to show current container background, second color is NEXT container's background color */ /* FAFAFA */
/*
.responsive-diagonal-bottom-FAFAFA {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom right, rgba(0,0,0,0) 49%, #FAFAFA 51%);
}
*/
/* first color is PRECEDING container's background color, second color is transparent to show current container background */
.responsive-diagonal-top-E2F3FF {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom right,  #E2F3FF 49%, rgba(0,0,0,0) 51%);
}
</style>
<script>
export default {
    props: {
        backgroundColor: {
            type: String,
            default: null, // example '#000000'
        },
        maxWidth: {
            type: String,
            default: '1200px',
        },
        responsiveContainer: {
            type: Boolean,
            default: false,
        },
        responsiveDiagonalTopClass: {
            type: String,
            default: null,
        },
        responsiveDiagonalBottomClass: {
            type: String,
            default: null,
        },
        justify: {
            type: String,
            default: null,
        },
    },
    computed: {
        outerstyle() {
            return this.backgroundColor ? `background-color: ${this.backgroundColor};` : '';
        },
        innerStyle() {
            let hpad = '4px';
            let vpad = '4px';
            if (this.$vuetify.breakpoint.mdAndUp) {
                hpad = '20px';
                vpad = '20px';
            }

            return `max-width: ${this.maxWidth}; width: 100%; margin-top: 0px; margin-bottom: 0px; margin-left: auto; margin-right: auto; padding-top: ${vpad}; padding-bottom: ${vpad}; padding-left: ${hpad}; padding-right: ${hpad};`;
        },
    },
};
</script>
